import React, { createContext, useState, useEffect } from "react";
import "./App.css";
import PrivateRoutes from "./View/PrivateRoutes";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  redirect,
  useNavigate,
} from "react-router-dom";
import Dashboard from "../src/View/Dashboard/Index";
import "../src/assets/base.css";
import Main from "./Layout/Main";

import Login from "./Athentication/Login";

import { Toaster } from "react-hot-toast";

// import AddBlogCategory from './View/BlogCategory/AddBlogCategory';
// import ManageBlogCategory from './View/BlogCategory/ManageBlogCategory';
// import EditBlogCategory from './View/BlogCategory/EditBlogCategory';

import AddState from "./View/State/AddState";
import Addcountry from "./View/Country/Addcountry";
import EditCountry from "./View/Country/EditCountry";
import AddCity from "./View/City/AddCity";
import AddArea from "./View/Area/AddArea";
import AddLanguage from "./View/Language/AddLanguage";
import AddService from "./View/Service-type/AddService";
import AddCategory from "./View/Category/addCategory";
import SubCategory from "./View/sub-Category/SubCategory";
import Service from "./View/Service/Service";
import AddSlotTime from "./View/Slot/AddSlotTime";
import AddSlotDate from "./View/Slot/AddSlotDate";
import AddCoupon from "./View/Cupon/AddCupon";
import AddBooking from "./View/Booking/AddBooking";
import AddInterviewDate from "./View/InterviewDate/AddInterviewDate";
import AddInterviewTime from "./View/InterviewTime/AddInterviewTime";
import AddInterviewType from "./View/AddInterviewType/AddInterviewType";
import AddExperience from "./View/Experience/AddExperience";
import RegistrationFee from "./View/Registrationfee/RegistrationFee";
import WorkingTime from "./View/WorkingTime/WorkingTime";
import SpareParts from "./View/SpareParts/SpareParts";
import CategoryBanner from "./View/Banner/CategoryBanner";
import ServiceBanner from "./View/Banner/ServiceBanner";
import HomeBanner from "./View/Banner/HomeBanner";
import SubCategoryBanner from "./View/Banner/SubCategoryBanner";
import VendarDetails from "./View/Vendar/VendarDetails";
import ViewVendor from "./View/Vendar/ViewVendor";
import AddHouseType from "./View/HouseType/AddHouseType";
import BuildingType from "./View/BuildingType/BuildingType";
import AddModelType from "./View/ModelType/AddModelType";
import AddPlaceType from "./View/PlaceType/AddPlaceType";
import AddMeterialType from "./View/MeterialType/AddMeterialType";
import Nopage from "./Loader/Nopage";
import AddHouseTypeDetails from "./View/HouseType/AddHouseTypeDetails";
import AreaIndivisual from "./View/AreaIndivisual/AreaIndivisual";
import AddPaintingBrands from "./View/Painting/AddPaintingBrands";
import AddPaintingQuality from "./View/Painting/AddPaintingQuality";
import AddPaintingVersion from "./View/Painting/AddPaintingVersion";
import AddVendorbanner from "./View/Vendar/AddVendorbanner";
import AddVendarInterviewQuestion from "./View/Vendar/AddVendarInterviewQuestion";
import AddServiceDays from "./View/Vendar/AddServiceDays";
import AddVendarQuestionAnswar from "./View/Vendar/AddVendarQuestionAnswar";
import ViewContuctUs from "./View/Contuctus/ViewContuctUs";
import VendarSubscription from "./View/Vendar/VendarSubscription";
import ServicePakages from "./View/ServicePakages/ServicePakages";
import AddDiscountPakeges from "./View/Discount Pakeges/AddDiscountPakeges";
import AddOrderService from "./View/OfferServiceImage/AddOrderService";
import AddAboutService from "./View/AboutService/AddAboutService";
import AddPaintingCost from "./View/PaintingCost/AddPaintingCost";
import AddModelCost from "./View/ModelType/AddModelCost";
import AddNoofBuilding from "./View/NoofBuilding/AddNoofBuilding";
import AddAMCprice from "./View/AMCprice/AddAMCprice";

const LOADINGDATA = createContext();
function App() {
  const [loader, setLoader] = useState(true);
  const[opensidebar,setopenSidebar]=useState(false)
  console.log("main"+opensidebar)

  return (
    <React.Fragment>
      <LOADINGDATA.Provider value={{ loader: loader, setloader: setLoader ,opensidebar:opensidebar,setopenSidebar:setopenSidebar}}>
        <Toaster />
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<Main />}>
              <Route path="/" element={<Dashboard />} />

              {/* <Route path="/add-blog-category" element={<AddBlogCategory/>}/>
            {/* <Route path="/add-blog-category" element={<AddBlogCategory/>}/>
              <Route path='/manage-blog-category' element={<ManageBlogCategory/>}/>
              <Route path='/edit-blog-category' element={<EditBlogCategory/>}/> */}

              <Route path="/add-country" element={<Addcountry />} />
              <Route path="/add-state" element={<AddState />} />
              <Route path="/add-city" element={<AddCity />} />
              <Route path="/add-area" element={<AddArea />} />
              <Route path="/add-Language" element={<AddLanguage />} />

              <Route path="/add-service" element={<AddService />} />
              <Route path="/add-category" element={<AddCategory />} />
              <Route path="/add-subcategory" element={<SubCategory />} />
              <Route path="/service" element={<Service />} />
              <Route path="/service-date" element={<AddSlotDate />} />
              <Route path="/service-time" element={<AddSlotTime />} />
              <Route path="/Add-coupon" element={<AddCoupon />} />
              <Route path="/Add-booking" element={<AddBooking />} />

              <Route path="/Add-interviewdate" element={<AddInterviewDate />} />
              <Route path="/AddInterviewTime" element={<AddInterviewTime />} />
              <Route path="/AddInterviewType" element={<AddInterviewType />} />
              <Route path="/AddExperience" element={<AddExperience />} />
              <Route path="/RegistrationFee" element={<RegistrationFee />} />
              <Route path="/WorkingTime" element={<WorkingTime />} />
              <Route path="/SpareParts" element={<SpareParts />} />
              <Route path="/CategoryBanner" element={<CategoryBanner />} />
              <Route path="/ServiceBanner" element={<ServiceBanner />} />
              <Route path="/HomeBanner" element={<HomeBanner />} />
              <Route path="/Category_Banner" element={<CategoryBanner />} />
              <Route
                path="/SubCategory_Banner"
                element={<SubCategoryBanner />}
              />

              <Route path="/VendarDetails" element={<VendarDetails />} />
              {/* <Route path="/VendarView/:Vid" element={<ViewVendor />} />
              <Route path="/VendarView/:Vid" element={<ViewVendor />} /> */}

              <Route path="/AddHouseType" element={<AddHouseType />} />

              <Route
                path="/AddHouseType/:Sid/:Cid"
                element={<AddHouseTypeDetails />}
              />

              <Route path="/AddBuildingType" element={<BuildingType />} />
              <Route path="/AddPlaceType" element={<AddPlaceType />} />
              <Route path="/AddModelType" element={<AddModelType />} />
              <Route path="/AddMeterialType" element={<AddMeterialType />} />
              <Route path="/AreaIndivisual" element={<AreaIndivisual />} />

              <Route
                path="/AddPaintingBrands"
                element={<AddPaintingBrands />}
              />

              <Route path="/AddPaintingQuality" element={<AddPaintingQuality />} />
              <Route path="/AddPaintingVersion" element={<AddPaintingVersion />} />
              <Route path="/AddVendorbanner" element={<AddVendorbanner />} />
              <Route path="/AddVendarInterviewQuestion" element={<AddVendarInterviewQuestion />} />

              <Route path="/AddServiceDays" element={<AddServiceDays />} />
              <Route path="/AddVendarQuestionAnswar" element={<AddVendarQuestionAnswar />} />
              
              <Route path="/ViewContuctUs" element={<ViewContuctUs />} />
              <Route path="/AddSubscription" element={<VendarSubscription />} />
              <Route path="/ServicePakages" element={<ServicePakages />} />
              <Route path="/AddDiscountPakeges" element={<AddDiscountPakeges/>} />
              <Route path="/AddOrderService" element={<AddOrderService/>} />
              <Route path="/AddAboutService" element={<AddAboutService/>} />
              <Route path="/AddPaintingCost" element={<AddPaintingCost/>} />
              <Route path="/AddModelCost" element={<AddModelCost/>} />
              <Route path="/AddNoofBuilding" element={<AddNoofBuilding/>} />

              <Route path="/AddAMCprice" element={<AddAMCprice/>} />

              
              
              
              

              
              
              
              
              
              



              <Route path="/*" element={<Nopage />} />
            </Route>
          </Route>

          <Route path="/login" element={<Login />} />
        </Routes>
      </LOADINGDATA.Provider>
    </React.Fragment>
  );
}

export { LOADINGDATA };
export default App;
