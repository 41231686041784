import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const VendarDetails = () => {
  const [name, setName] = useState("");
  const [processtype, setprocesstype] = useState("");
  const [interview_address, setinterview_address] = useState("");
  const [InterviewAddresssVerification, setInterviewAddresssVerification] =
    useState("");
  const [document_Verification, setdocument_Verification] = useState([]);
  const [IntVerification, setIntVerification] = useState("");
  const [hrRound, sethrRound] = useState("");
  console.log("hrRound", hrRound);
  const [technicalRound, settechnicalRound] = useState("");
  const [docvarifyStatus, setdocvarifyStatus] = useState("");

  const [mettLink, setmettLink] = useState("");
  const [mettStatus, setmeetingStatus] = useState(false);
  const [MeetingTime, setMeetingTime] = useState("");

  const [state_id, setstate_id] = useState("");

  console.log("document_Verification", document_Verification);
  const [allState, setAllState] = useState([]);
  const [allCity, setAllCity] = useState([]);
  // const [totalCountry, settotalCountry] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    fetchAllVendar();
  }, []);

  // const updateDocvarify_data = (index,type, value) => {
  //     const updateddata = [...document_Verification];
  //     updateddata[index] = value;
  //     setdocument_Verification(updateddata);
  // };
  const resetData = () => {
    setprocesstype();
    setinterview_address();
    setInterviewAddresssVerification();
    setdocument_Verification();
    sethrRound();
    settechnicalRound();
    setdocvarifyStatus();
    setmettLink();
    setmeetingStatus();
    setMeetingTime();
    setstate_id();
    setAllCity();
  };

  const updateDocvarify_data = (index, field, value) => {
    const updateddata = [...document_Verification];

    if (value == "true") {
      updateddata[index][field] = true;
    } else {
      updateddata[index][field] = false;
    }
    setdocument_Verification(updateddata);
  };

  const onEdit = (item) => {
    window.scrollTo(0, 0);
    console.log("4", item);
    setName(item?.serviceName);
    setdocument_Verification(item?.interviewProcessData?.document_Verification);
    setprocesstype(item?.interviewProcessData?.processtype);
    setinterview_address(item?.interviewProcessData?.interview_address);
    setdocvarifyStatus(
      item?.interviewProcessData?.DocumentsVerification_status
    );
    // setIntVerification(IntVerification)
    setmettLink(item?.interviewProcessData?.onlineMeetingLink);
    setInterviewAddresssVerification(
      item?.interviewProcessData?.InterviewAddresssVerification
    );
    sethrRound(item?.interviewProcessData?.hrRound);
    settechnicalRound(item?.interviewProcessData?.technicalRound);
    setmeetingStatus(item?.interviewProcessData?.onlineMeetingStatus);
    setMeetingTime(item?.interviewProcessData?.MeetingTime);
    // setonlinemeetingstatus(item?.interviewProcessData?.onlineMeetingStatus)
    // setmeetingStatus(item?interviewProcessData?.MeetingTime)
    setId(item?.interviewProcessData?._id);
    setHide(false);
  };

  const UpdateStateCategory = () => {
    console.log("ID", id);
    const data = {
      processtype: processtype,
      interview_address: interview_address,
      InterviewAddresssVerification: InterviewAddresssVerification,
      document_Verification: document_Verification,
      DocumentsVerification_status: docvarifyStatus,
      onlineMeetingLink: mettLink,
      onlineMeetingStatus: mettStatus,
      MeetingTime: MeetingTime,
      hrRound: hrRound,
      technicalRound: technicalRound,
    };
    HomeService.UpdateVendar(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          resetData();
          fetchAllVendar();
        } else {
          toast.error(" data Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllVendar = () => {
    HomeService.MannageVendar()
      .then((res) => {
        console.log("ResAllBlogvend", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data?.map((item, index) => {
            console.log("vendorId", item);
            return {
              sl: index + 1,
              Name: item?.name,
              mobileNo: item?.mobileNo,
              DocumentsVerification_status:
                item?.interviewProcessData?.DocumentsVerification_status,

              image: IMAMGE_URL + item?.image,
              interviewProcessData: item?.interviewProcessData,
              processtype: item?.interviewProcessData?.processtype,

              InterviewAddresssVerification:
                item?.interviewProcessData?.InterviewAddresssVerification,
              onlineMeetingLink: item?.interviewProcessData?.onlineMeetingLink,
              onlineMeetingStatus:
                item?.interviewProcessData?.onlineMeetingStatus,
              MeetingTime: item?.interviewProcessData?.MeetingTime,
              hrRound: item?.interviewProcessData?.hrRound,
              technicalRound: item?.interviewProcessData?.technicalRound,

              slot: item?.interviewData?.slot,
              photos: IMAMGE_URL + item?.interviewData?.photos[0],
              selectDate: item?.interviewData?.selectDate,
              selectTime: item?.interviewData?.selectTime,
              aadhaarCard: IMAMGE_URL + item?.interviewData?.aadhaarCard[0],
              pancard: IMAMGE_URL + item?.interviewData?.pancard,
              enterBankAccountNumber:
                item?.interviewData?.enterBankAccountNumber,
              IFSCcode: item?.interviewData?.IFSCcode,
              certificates: IMAMGE_URL + item?.interviewData?.certificates,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  {/* <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg> */}
                </div>
              ),
            };
          });
          setAllCity(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Vendar image
        </div>
      ),
      selector: (row) => (
        <a href={row.image} target="_blank">
          <img style={{ width: 100 }} src={row.image} alt={""} />
        </a>
      ),
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Vendar Name
        </div>
      ),
      selector: (row) => row.Name,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Mobile No
        </div>
      ),
      selector: (row) => row.mobileNo,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Mode of interview
        </div>
      ),
      selector: (row) => row.slot,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          interview Date
        </div>
      ),
      selector: (row) => row.selectDate,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          interview Time
        </div>
      ),
      selector: (row) => row.selectTime,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Photos
        </div>
      ),
      selector: (row) => (
        <a href={row.photos} target="_blank">
          <img style={{ width: 100 }} src={row.photos} alt={""} />
        </a>
      ),
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Adhaar Card
        </div>
      ),
      selector: (row) => (
        <a href={row.aadhaarCard} target="_blank">
          <img style={{ width: 100 }} src={row.aadhaarCard} alt={""} />
        </a>
      ),
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Pan Card
        </div>
      ),
      selector: (row) => (
        <a href={row.pancard} target="_blank">
          <img style={{ width: 100 }} src={row.pancard} alt={""} />
        </a>
      ),
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Certificate
        </div>
      ),
      selector: (row) => (
        <a href={row.certificates} target="_blank">
          <img style={{ width: 100 }} src={row.certificates} alt={""} />
        </a>
      ),
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          BankAccountNumber
        </div>
      ),
      selector: (row) => row.enterBankAccountNumber,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          BankAccountNumber
        </div>
      ),
      selector: (row) => row.enterBankAccountNumber,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          IFSCcode
        </div>
      ),
      selector: (row) => row.IFSCcode,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          DocumentsVerification Status
        </div>
      ),
      selector: (row) =>
        row.DocumentsVerification_status ? (
          <span style={{ color: "green" }}>Done</span>
        ) : (
          <span style={{ color: "red" }}>Pending..</span>
        ),
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          View All Document Status
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add vendar
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Vendar
              </div>
            )}

            {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                category Name<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => setstate_id(e.target.value)}>
                                <option value="" disabled selected>
                                    Select
                                </option>
                                {allState?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == state_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> */}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Processtype<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setprocesstype(e.target.value);
                }}
                value={processtype}
              >
                <option value="">Select</option>
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </select>
            </div>

            <div
              class="form-group"
              style={{ display: processtype == "offline" ? "block" : "none" }}
            >
              <label for="exampleInputEmail1">
                Interview Address <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={interview_address}
                onChange={(e) => setinterview_address(e.target.value)}
              />
            </div>

            <div
              class="form-group"
              style={{ display: processtype == "online" ? "block" : "none" }}
            >
              <label for="exampleInputEmail1">
                Meeting Link <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={mettLink}
                onChange={(e) => setmettLink(e.target.value)}
              />
            </div>

            <div
              class="form-group"
              style={{ display: processtype == "online" ? "block" : "none" }}
            >
              <label for="exampleInputEmail1">
                MeetingTime<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={MeetingTime}
                onChange={(e) => setMeetingTime(e.target.value)}
              />
            </div>
            <div
              class="form-group"
              style={{ display: processtype == "online" ? "block" : "none" }}
            >
              <label for="exampleInputEmail1">
                Online Meeting Status<span style={{ color: "red" }}>*</span> :
                <div
                  onClick={(e) => {
                    setmeetingStatus(e.target.value == "true");
                  }}
                  style={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    float: "right",
                    marginLeft: "2rem",
                  }}
                >
                  <input
                    type="radio"
                    name="meetstatus"
                    checked={mettStatus === true}
                    value={true}
                  ></input>
                  True
                  <input
                    type="radio"
                    name="meetstatus"
                    checked={mettStatus === false}
                    value={false}
                  ></input>
                  False
                </div>
              </label>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Document Verification<span style={{ color: "red" }}>*</span> :
                <div
                  onChange={(e) => {
                    setdocvarifyStatus(e.target.value === "true");
                  }}
                  style={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    float: "right",
                    marginLeft: "2rem",
                  }}
                >
                  <input
                    type="radio"
                    name="doc_varify"
                    checked={docvarifyStatus === true}
                    value={true}
                  ></input>
                  True
                  <input
                    type="radio"
                    name="doc_varify"
                    checked={docvarifyStatus === false}
                    value={false}
                  ></input>
                  False
                </div>
              </label>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Interview Addresss Verification
                <span style={{ color: "red" }}>*</span> :
                <div
                  onChange={(e) => {
                    setInterviewAddresssVerification(e.target.value === "true");
                  }}
                  style={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    float: "right",
                    marginLeft: "2rem",
                  }}
                >
                  <input
                    type="radio"
                    name="intviewadres"
                    checked={InterviewAddresssVerification === true}
                    value={true}
                  ></input>
                  True
                  <input
                    type="radio"
                    name="intviewadres"
                    checked={InterviewAddresssVerification === false}
                    value={false}
                  ></input>
                  False
                </div>
              </label>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Hr Round<span style={{ color: "red" }}>*</span> :
                <div
                  onChange={(e) => {
                    sethrRound(e.target.value === "true");
                  }}
                  style={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    float: "right",
                    marginLeft: "2rem",
                  }}
                >
                  <input
                    type="radio"
                    name="hrround"
                    checked={hrRound === true}
                    value={true}
                  ></input>
                  True
                  <input
                    type="radio"
                    name="hrround"
                    checked={hrRound === false}
                    value={false}
                  ></input>
                  False
                </div>
              </label>
            </div>
            {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                Interview Verification<span style={{ color: "red" }}>*</span> :
                                <div onChange={(e) => { setIntVerification(e.target.value) }} style={{ display: "flex", gap: "2rem", alignItems: "center", float: "right", marginLeft: "2rem" }}>
                                    <input type="radio" name="intvarify" checked={IntVerification === true} value={true} ></input>True
                                    <input type="radio" name="intvarify" checked={IntVerification === false} value={false} ></input>False
                                </div>


                            </label >
                        </div> */}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Technical Round<span style={{ color: "red" }}>*</span> :
                <div
                  onChange={(e) => {
                    settechnicalRound(e.target.value === "true");
                  }}
                  style={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    float: "right",
                    marginLeft: "2rem",
                  }}
                >
                  <input
                    type="radio"
                    name="techround"
                    checked={technicalRound == true}
                    value={true}
                  ></input>
                  True
                  <input
                    type="radio"
                    name="techround"
                    checked={technicalRound == false}
                    value={false}
                  ></input>
                  false
                </div>
              </label>
            </div>

            <div class="form-group" style={{ border: "2px solid red" }}>
              <span style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                document varification Status
              </span>

              {document_Verification?.map((ele, id) => {
                return (
                  <div
                    key={ele._id}
                    className="form-group"
                    style={{ margin: "2rem" }}
                  >
                    <label htmlFor="exampleInputEmail1">
                      {ele.type}
                      <span style={{ color: "red" }}>*</span> :
                      <div
                        style={{
                          display: "flex",
                          gap: "2rem",
                          alignItems: "center",
                          float: "right",
                          marginLeft: "2rem",
                        }}
                      >
                        <input
                          onChange={(e) => {
                            updateDocvarify_data(id, "status", e.target.value);
                          }}
                          type="radio"
                          name={`doc_varify_${id}`}
                          checked={ele.status}
                          value={true}
                        />
                        True
                        <input
                          onChange={(e) => {
                            updateDocvarify_data(id, "status", e.target.value);
                          }}
                          type="radio"
                          name={`doc_varify_${id}`}
                          checked={!ele.status}
                          value={false}
                        />
                        False
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>

            {hide ? (
              <button class="btn btn-primary">Submit</button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateStateCategory}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Vendar
            </div>
            <DataTable columns={columns} data={allCity} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default VendarDetails;
