
const columns = [
    {
        name: <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>SL</div>,
        selector: (row) => row.sl,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Name
            </div>
        ),
        selector: (row) => row.name,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Image
            </div>
        ),
        selector: (row) => <img style={{ width: 100 }} src={row.image} alt={""} />,
    },
   
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Address
            </div>
        ),
        selector: (row) => row.address,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Landmark
            </div>
        ),
        selector: (row) => row.landmark,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                City
            </div>
        ),
        selector: (row) => row.city,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                MobileNumber
            </div>
        ),
        selector: (row) => row.mobileNumber,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                AddressType
            </div>
        ),
        selector: (row) => row.addressType,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Quantity
            </div>
        ),
        selector: (row) => row.quantity,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Subtotal
            </div>
        ),
        selector: (row) => row.subtotal,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Price
            </div>
        ),
        selector: (row) => row.price,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Total
            </div>
        ),
        selector: (row) => row.total,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                ItemDiscount
            </div>
        ),
        selector: (row) => row.itemDiscount,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Couponcode
            </div>
        ),
        selector: (row) => row.couponCode,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                CouponPrice
            </div>
        ),
        selector: (row) => row.couponPrice,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                MembershipDiscount
            </div>
        ),
        selector: (row) => row.membershipDiscount,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                TotalAmount
            </div>
        ),
        selector: (row) => row.totalAmount,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                PaymentMethod
            </div>
        ),
        selector: (row) => row.paymentMethod,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Paymenttype
            </div>
        ),
        selector: (row) => row.paymenttype,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                BookingStatus
            </div>
        ),
        selector: (row) => row.bookingStatus,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Booking Date
            </div>
        ),
        selector: (row) => row.booking_date,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                Discount Percent
            </div>
        ),
        selector: (row) => row.discount_percent,
    },
    {
        name: (
            <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                UpdatedOn
            </div>
        ),
        selector: (row) => row.updatedOn,
    },




    {
        name: (
            <div
                style={{ fontSize: "14px", color: '#495057', marginLeft: "15px", fontWeight: "bolder" }}
            >
                Action
            </div>
        ),
        selector: (row) => row.action,
    },
];



const filterdate = (date) => {
    const dateString = date;
    const extractedDate = dateString.split("T")[0];
    const formattedDate = new Date(extractedDate).toISOString().split("T")[0];
    console.log("formattedDate", formattedDate);
    return (formattedDate)
}

export { columns ,filterdate}