import React from 'react'
import "./Loader.css"
function Loader() {

    return (
        <>
            <div class="loader"></div>
        </>
    )
}

export default Loader