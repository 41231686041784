import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";

const AddPaintingQuality = () => {
  const [name, setName] = useState("");
  const [price, setprice] = useState("");
  const [feature, setfeature] = useState("");
const[inputType,setinputType]=useState("")
const[dropdownoption,setdropdownoption]=useState([])

  const [state_id, setstate_id] = useState("");

  console.log(state_id);
  const [allLanguage, setAllLanguage] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    fetchAllpaintingquality();
  }, []);

  const ResetState = () => {
    setName("");
    setprice("");
    setfeature("");
  };
  const onEdit = (item) => {
    window.scrollTo(0, 0);
    console.log("ittem", item);
    setfeature(item?.feature)
    setprice(item?.cost)
    setName(item?.name);
    setId(item?._id);
    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeletePaintingQuality(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllpaintingquality();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const options = [
    { value: 'washable', label: 'Washable' },
    { value: 'anti-microbial', label: 'Anti-microbial' },
    { value: 'elastomeric', label: 'Elastomeric' },
    { value: 'Vibrant-Colors', label: 'Vibrant Colors' },
    { value: 'durable', label: 'Durable' },
    { value: 'doesnot-fade', label: "does not fade" },
    { value: 'Good-color-range', label: '  Good color range' },
  
  
    
  ];

  const fetchAllpaintingquality = () => {
    HomeService.MannagePaintingQuality()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)
          setinputType( res?.data[0]?.inputType)
          let arr = res?.data?.map((item, index) => {
            console.log(item);
            return {
              sl: index + 1,
              Name: item?.name,
              Cost: item?.cost,
              feature: item?.feature,
              serviceTypeData: item?.serviceTypeData.serviceName,
              categoryData: item?.categoryData.name,
              inputType:item?.inputType,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setAllLanguage(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Name
        </div>
      ),
      selector: (row) => row.serviceTypeData,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category Name
        </div>
      ),
      selector: (row) => row.categoryData,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Painting Quality
        </div>
      ),
      selector: (row) => row.Name,
    },
    

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Price
        </div>
      ),
      selector: (row) => row.Cost,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Features
        </div>
      ),
      selector: (row) => row.feature,
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //      Input Type
    //     </div>
    //   ),
    //   selector: (row) => row.inputType,
    // },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];
  
  const AddInputTypedata = () => {
    let data = {
    inputType:inputType
    };

    if (inputType) {
      // alert(name + country_id)
      HomeService.AddprintingQualityinputtype(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllpaintingquality();
            ResetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!inputType) {
        toast.error("inputType Field Is Required");
      }
    }
  };

  const addPaintingQuality = () => {
    let data = {
      name: name,
      cost: price,
      feature: feature,
    };

    if (name && price && feature) {
      // alert(name + country_id)
      HomeService.AddPaintingQuality(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllpaintingquality();
            ResetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!name) {
        toast.error("paintingQuality Name Field Is Required");
      }
      if (!price) {
        toast.error("Price Field Is Required");
      }
      if (!feature) {
        toast.error("Feature Field Is Required");
      }
    }
  };

  const UpdateLanguageCategory = () => {
    console.log("ID", id);
    let data = {

      name: name,
      cost:price,
      feature:feature
    };
    HomeService.UpdatePaintingQuality(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          setName("");
          setprice("")
          setfeature("")
          fetchAllpaintingquality();
        } else {
          toast.error("Painting Quality Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (selectedOption) => {
    const temparr= selectedOption.map((ele,id)=>{
         return({name:ele.value})
     })
     // const temparr=[...dropdownoption]
     setdropdownoption(temparr)

 //   console.log(`Selected Option:`, temparr);
 };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Painting Quality
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Painting Quality
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Painting Quality<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Cost<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={price}
                onChange={(e) => setprice(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Feature<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={feature}
                onChange={(e) => setfeature(e.target.value)}
              />
            </div>
          
          {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                Whitewash<span style={{ color: "red" }}>*</span> :
                            </label>

  <Select
        options={options}
        onChange={handleChange}
        isMulti
        placeholder="Select an option..."
      />

</div> */}

            {hide ? (
              <button class="btn btn-primary" onClick={addPaintingQuality}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateLanguageCategory}>
                Update
              </button>
            )}

              {/* <div class="form-group" style={{display:"flex",gap:"0.2rem", marginTop:"3rem"}}>
                <div style={{display:"flex",gap:"0.2rem", }}>
                <label class="form-label" for="exampleInputEmail1">
                Input Type
              </label>
              <select class="form-label"
                 value={inputType} // Set the selected value
                onChange={(e) => {
                  
                  setinputType(e.target.value);
                 
                }}
              >

                <option value="">Select</option>
                <option value="textinput">Text Input</option>
                <option value="radiobutton">Radio Button</option>
                <option value="dropdown">Dropdown</option>
                <option value="checkbox">CheckBox</option>

              
              </select>
  </div>
              
              <button class="textinpbtn form-label"onClick={AddInputTypedata}>
                Update 
              </button>
            </div> */}



            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Painting Quality
            </div>
            <DataTable columns={columns} data={allLanguage} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaintingQuality;
