import React, { useState } from "react";

function SeeMoreLess({ children, maxWordShow = 50 }) {
  console.log("ghh",children)
  const [sliceCount, setSliceCount] = useState(maxWordShow);
  return (
    <div>
      {children?.length > 50 ? (
        <>
          {children.slice(0, sliceCount)}
          ...{" "}
          {sliceCount === children?.length ? (
            <span
              style={{ color: "grey", cursor: "pointer" }}
              onClick={() => setSliceCount(maxWordShow)}
            >
              see less
            </span>
          ) : (
            <span
              style={{ color: "grey", cursor: "pointer" }}
              onClick={() => setSliceCount(children?.length)}
            >
              see more{" "}
            </span>
          )}
        </>
      ) : (
        children
      )}
    </div>
  );
}

export default SeeMoreLess;
