import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import SeeMoreLess from "../../Component/SeeMoreLess";

const AddAboutService = () => {
  const [name, setName] = useState("");
  const[description,setdescription]=useState("")
  const [state_id, setstate_id] = useState("");

  console.log(state_id);
  const [allLanguage, setAllLanguage] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    fetchAllLanguage();
  }, []);

  const onEdit = (item) => {
    window.scrollTo(0, 0);
    console.log("ittem", item);
    setName(item?.title);
    setdescription(item?.description)
    setId(item?._id);
    setHide(false);
  };

//   const onDelete = (id) => {
//     Swal.fire({
//       title: "Are you sure?",
//       // text: "You won't  to delete this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         HomeService.DeleteLanguage(id)
//           .then((res) => {
//             console.log("DELETEBLOGCAT", res);
//             if (res && res.status) {
//               toast.success("Deleted Successfully");
//               console.log("RESPONSEDELETE", res);
//               fetchAllLanguage();
//             } else {
//               toast.error("Failed to Delete the Item ");
//             }
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       }
//     });
//   };


  const fetchAllLanguage = () => {
    HomeService.MannageAboutService()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)
        const item = res?.data;
        console.log("kkk",item)
        const arr=[];
         arr[0]={
             
              Name: item?.title,
              description:item?.description,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  {/* <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg> */}
                </div>
              ),
            };
            
          
          setAllLanguage(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const addAboutService = () => {
    let data = {
      title: name,
      description:description
    };

    if (name && description) {
      // alert(name + country_id)
      HomeService.addAboutService(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllLanguage();
            
            setName("");
            setstate_id("");
          } else {
            setName("")
            setdescription("")
            toast.success(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!name) {
        toast.error("Title Field Is Required");
      }
      if (!description) {
        toast.error("Description Field Is Required");
      }
    }
  };

  const columns = [
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       SL
    //     </div>
    //   ),
    //   selector: (row) => row.sl,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Language Name
        </div>
      ),
      selector: (row) => row.Name,
    },
    {
        name: (
          <div
            style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
          >
          Description
          </div>
        ),
        selector: (row) => <SeeMoreLess maxWordShow={50} >{row.description}</SeeMoreLess>,
        wrap: true

      },
    

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  // const updateaboutService = () => {
  //   console.log("ID", id);
  //   let data = {
  //     title: name,
  //     description:description
  //   };
  //   HomeService.addAboutService( data)
  //     .then((res) => {
  //       console.log("updated res", res);
  //       if (res && res.status) {
  //         toast.success("Updated Successfully");
  //         setHide(true);
  //         console.log("RESPONSEDELETE", res);
  //         setName("");
  //         fetchAllLanguage();
  //       } else {
  //         toast.error("About Service Name Already Exist");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add About Service
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit About Service
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
               Title<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
               Description<span style={{ color: "red" }}>*</span> :
              </label>
              <textarea
             
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              />
            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={addAboutService}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={addAboutService}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage About Service
            </div>
            <DataTable columns={columns} data={allLanguage} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAboutService;
