import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";

const AddServiceDays = () => {
  const [days, setdays] = useState("");
  const [price, setPrice] = useState("");
  const [service_id, setservice_id] = useState("");
  const [catagory_id, setcategory_id] = useState("");
const[inputType,setinputType]=useState("")
  console.log(service_id);
  const [allService, setallSarvice] = useState([]);
  const [servicedays, setservicedays] = useState([]);
  const [totaldate, settotaldate] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    FetchAllServicedays();
    fetchAllSarvice();
  }, []);

  const resetState = () => {
    setdays("");
    setPrice("");
    setservice_id("");
    setcategory_id("");
  };

  const onEdit = (item) => {
    window.scrollTo(0, 0);
    console.log("ittem", item);
    setdays(item?.days);
    setPrice(item?.price)
    setId(item?._id);
    setservice_id(item.serviceTypeID);
    setcategory_id(item?.serviceCategoryID)
    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteServiceDays(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              FetchAllServicedays();
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              // fetchAllState();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const fetchAllSarvice = () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          setallSarvice(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllCategory = (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          settotaldate(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  
//   AddServiceDays,
//   MannageServiceDays,
//   UpdateServiceDays,
//   DeleteServiceDays

  const FetchAllServicedays = () => {
    HomeService.MannageServiceDays()
      .then((res) => {
        console.log("ResArllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data?.map((item, index) => {
            console.log(item);
            return {
              sl: index + 1,
              Name: item?.days,
              price: item?.price,
              serviceName: item?.serviceTypeData?.serviceName,
              CategoryName: item?.categoryData?.name,
              inputType:item?.inputType,
              // createdOn: filterdate(item?.servicedate_data[0].servicedate),

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setservicedays(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const addServiceDays = () => {
    let data = {
      serviceTypeID: service_id,
      serviceCategoryID: catagory_id,
      days: days,
      price: price,
    };
    // serviceTime: name

    if (days && service_id && catagory_id && price) {
      // alert(name + country_id)
      HomeService.AddServiceDays(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            FetchAllServicedays();
            toast.success(res.message);

            resetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!days) {
        toast.error(" Service Days Field Is Required");
      }
      if (!service_id) {
        toast.error("Service type Name Field Is Required");
      }
      if (!catagory_id) {
        toast.error("Category Is Required");
      }
      if (!price) {
        toast.error("Price Is Required");
      }
    }
  };

  const AddInputTypedata = () => {
    let data = {
   inputType:inputType
    };
    // serviceTime: name

    if (inputType) {
      // alert(name + country_id)
      HomeService.Addservicedaysinputtype(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            FetchAllServicedays();
            toast.success(res.message);

            resetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!inputType) {
        toast.error("Input Type Field Is Required");
      }
     
    }
  };

  

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type Name
        </div>
      ),
      selector: (row) => row.serviceName,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category Name
        </div>
      ),
      selector: (row) => row.CategoryName,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
         Service days
        </div>
      ),
      selector: (row) => row.Name,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Price
        </div>
      ),
      selector: (row) => row.price,
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       InputType
    //     </div>
    //   ),
    //   selector: (row) => row.inputType,
    // },
    
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const UpdateServicedays = () => {
    console.log("ID", id);
    let data = {
      serviceTypeID: service_id,
      serviceCategoryID: catagory_id,
      days: days,
    };

    HomeService.UpdateServiceDays(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          setdays("");
          setservice_id("");
          setcategory_id("")
          setPrice("")
          FetchAllServicedays();
        } else {
          toast.error("Service Days Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Service Days
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Service Days
              </div>
            )}

            <div class="form-group" style={{display:id?"none":"block"}}>
              <label for="exampleInputEmail1">
              Service Type
                <span style={{ color: "red" }}>
                  (serviceType must be Contract Services)*
                </span>{" "}
                :
              </label>
              <select
                onChange={(e) => {
                  setservice_id(e.target.value);
                   fetchAllCategory(e.target.value);
                }}
              >
                <option value="">Select</option>
                {allService?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == service_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group"style={{display:id?"none":"block"}}>
              <label for="exampleInputEmail1">
                Category Name <span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => setcategory_id(e.target.value)}>
                <option value="">Select</option>
                {totaldate?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == catagory_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
              Service Days <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={days}
                onChange={(e) => setdays(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Price <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={addServiceDays}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateServicedays}>
                Update
              </button>
            )}
{/* 
<div class="form-group" style={{display:"flex",gap:"0.2rem", marginTop:"3rem"}}>
                <div style={{display:"flex",gap:"0.2rem", }}>
                <label class="form-label" for="exampleInputEmail1">
                Input Type
              </label>
              <select class="form-label"
                 value={inputType} // Set the selected value
                onChange={(e) => {
                  
                  setinputType(e.target.value);
                 
                }}
              >

                <option value="">Select</option>
                <option value="textinput">Text Input</option>
                <option value="radiobutton">Radio Button</option>
                <option value="dropdown">Dropdown</option>
                <option value="checkbox">CheckBox</option>

              
              </select>
  </div>
              
              <button class="textinpbtn form-label"onClick={AddInputTypedata}>
                Update 
              </button>
            </div> */}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Service Days
            </div>
            <DataTable columns={columns} data={servicedays} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddServiceDays;

