import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import SeeMoreLess from "../../Component/SeeMoreLess";

const AddPaintingCost = () => {
    const [name, setName] = useState("");
    const [price, setprice] = useState("");
    const [feature, setfeature] = useState("");
    const [whitewash, setwhitewash] = useState('');
    const[dropdownoption,setdropdownoption]=useState([])
    const[inputType,setinputType]=useState("")

   

    const [allLanguage, setAllLanguage] = useState([]);

    const [meterial_id, setmeterial_id] = useState("");
    const [paintingquality_id, setpaintingquality_id] = useState("");
    const[placetypeid,setPlacetypeid]=useState("");
    const[paintVer_id,setpaintVer_id]=useState("");
    
     
  const [allpaintingquality, setallpaintingquality] = useState([]);
  const [allmeteritype, setallmeteritype] = useState([]);
  const [allplacetype, setAllplacetype] = useState([]);

  const [service_id, setservice_id] = useState("");
  const [catagory_id, setcategory_id] = useState("");
  const [allService, setallSarvice] = useState([]);
  const [totalCategory, settotalCategory] = useState([]);
  const[totalPaintVersion,settotalPaintVersion]=useState([])


  

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    useEffect(() => {
        fetchAllLpaintinCost();
         fetchAllplacetype()
        fetchAllmeterialType()
          fetchAllpaintingquality()
        fetchAllSarvice()
        fetchPaintingVersion()
      
    }, []);

    const ResetState = () => {
        setName("");
        setprice("");
        setfeature("");
        setmeterial_id("")
        setpaintingquality_id("")
        setPlacetypeid("")
        setdropdownoption("")
    };

    const fetchAllSarvice = () => {
        HomeService.ManageService()
          .then((res) => {
            console.log("AllState", res.data);
            if (res && res?.status) {
              // setLoader(false)
              let arr = res?.data;
              setallSarvice(arr);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };

      const fetchPaintingVersion= () => {
        HomeService.MannagePaintingVersion()
          .then((res) => {
            console.log("AllStgate", res.data);
            if (res && res?.status) {
              // setLoader(false)
              let arr = res?.data;
              settotalPaintVersion(arr);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };
    
      const fetchAllCategory = (id) => {
        HomeService.CategoriesviaService_type(id)
          .then((res) => {
            console.log("AllState", res.data);
            if (res && res?.status) {
              // setLoader(false)
              let arr = res?.data;
              settotalCategory(arr);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };


    const onEdit = (item) => {
        window.scrollTo(0, 0);
        console.log("itemrr", item);
        if(item?.serviceTypeID){
          fetchAllCategory(item?.serviceTypeID);
          fetchAllmeterialType(item?.serviceCategoryID,item?.serviceTypeID);
          fetchPlaceType(item?.serviceCategoryID,item?.serviceTypeID);
        }
        setmeterial_id(item?.materialTypeId)
        setpaintingquality_id(item?.paintingQualityId)
        setpaintVer_id(item?.paintingVersionId)
        setcategory_id(item?.serviceCategoryID)
        setservice_id(item?.serviceTypeID)
        setPlacetypeid(item?.placetypeId)
        setprice(item?.cost)
        // setName(item?.name);
        setId(item?._id);
        setHide(false);
    };

    const options = [
        { value: 'whitewash', label: 'Whitewash' },
        { value: 'putty', label: 'Putty' },
        { value: 'papering', label: 'Papering' },
        { value: 'primer', label: 'Primer' },
        { value: 'painting', label: 'Painting' },
        
      ];
      const fetchAllplacetype = () => {
        HomeService.MannagePlaceType()
          .then((res) => {
            console.log("AllState", res.data);
            if (res && res?.status) {
              // setLoader(false)
              let arr = res?.data;
              setAllplacetype(arr);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };
    
      
      const fetchPlaceType = (catid,sid) => {
        const data={serviceCategoryID:catid}
        HomeService.AllPlaceTypeforserviceIdandMeterialid(sid,data)
          .then((res) => {
            console.log("AllStateme", res.data);
            if (res && res?.status) {
              // setLoader(false)
              let arr = res?.data;
              setAllplacetype(arr);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };

      const fetchAllmeterialType = (catid,sid) => {
        const data={serviceCategoryID:catid}
        HomeService.AllmeterialtypeforserviceIdandMeterialid(sid,data)
          .then((res) => {
            console.log("AllStateme", res.data);
            if (res && res?.status) {
              // setLoader(false)
              let arr = res?.data;
              setallmeteritype(arr);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };


      const fetchAllpaintingquality = () => {
        // const data={serviceCategoryID:catid}

        HomeService.MannagePaintingQuality()
          .then((res) => {
            console.log("AllStttate", res.data);
            if (res && res?.status) {
              // setLoader(false)
              let arr = res?.data;
              setallpaintingquality(arr);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };
       

    
    
    


    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                HomeService.DeletePaintingVersion(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            fetchAllLpaintinCost();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };


    


    const fetchAllLpaintinCost = () => {
        HomeService.MannagePaintingCost()
            .then((res) => {
                console.log("ResAllBlog", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                
                    let arr = res?.data?.map((item, index) => {
                        console.log("llll",item?.materialTypeName);
                        return {
                            sl: index + 1,
                            Name: item?.name,
                            Cost: item?.cost,
                          
                            
                          
                            placetype: item?.placeTypeData?.type,
                            paitQuality:item?.paintingQualityData?.name,
                            paintingvertion:item?.paintingVersionData?.name,
                            meterialval: item?.materialTypeData.type,

                         

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    {/* <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg> */}
                                </div>
                            ),
                        };
                    });
                    setAllLanguage(arr);
                }
                console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };
    

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    placeTypeName
                </div>
            ),
            selector: (row) => row.placetype,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Meterial Type
                </div>
            ),
            selector: (row) => row.meterialval,
        },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Painting Version
                </div>
            ),
            selector: (row) => <SeeMoreLess maxWordShow={50}>{row.paintingvertion}
            </SeeMoreLess>,
            wrap:true
        },


        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                   Painting Quality
                </div>
            ),
            selector: (row) => row.paitQuality,
        },
       
        
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Price
                </div>
            ),
            selector: (row) => row.Cost,
        },

        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Features
        //         </div>
        //     ),
        //     selector: (row) => row.feature,
        // },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Input Type
        //         </div>
        //     ),
        //     selector: (row) => row.inputType,
        // },
        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const addLanguage = () => {
      
      // "placeStatus":false,
      // "materialTypeId": "650305a7f1d0c63605785ad2",
      // "placetypeId":"650c0c9f9e14480433c83482" ,
      // "paintingVersionId": "650c3b40c4891f8afa5a7f77",
      // "cost": 7
        let data = {
          placeStatus:placetypeid?true:false,
          materialTypeId:meterial_id,
          placetypeId:placetypeid,
             paintingQualityId:paintingquality_id,
             paintingVersionId:paintVer_id,
             cost: price,
            
        };

        if (price&&paintVer_id) {
            // alert(name + country_id)
            HomeService.addPaintingCost(data)
                .then((res) => {
                    console.log("Response Add State", res);
                    if (res && res.status) {
                        toast.success(res.message);
                        fetchAllLpaintinCost();
                        ResetState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!price) {
                toast.error("price  Field Is Required");
            }
            if (!paintVer_id) {
              toast.error("price  Field Is Required");
          }
            
        }
    };
  
    


    const UpdateLanguageCategory = () => {
        console.log("ID", id);
        let data = {
            name: name,
        };
        HomeService.UpdatePaintingCost(id, data)
            .then((res) => {
                console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    setHide(true);
                    console.log("RESPONSEDELETE", res);
                    setName("");
                    fetchAllLpaintinCost();
                } else {
                    toast.error("Blog Category Name Already Exist");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };




    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add Painting Cost
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Edit Painting Cost
                            </div>
                        )}
                           <div class="form-group">
              <label for="exampleInputEmail1">
                ServiceType<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setservice_id(e.target.value);
                  fetchAllCategory(e.target.value);
                }}
              >
                <option value="">Select</option>
                {allService?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == service_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Category Name <span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => {setcategory_id(e.target.value);
              fetchAllmeterialType(e.target.value,service_id);
              fetchPlaceType(e.target.value,service_id)
              // fetchAllpaintingquality(e.target.value,service_id)
            }}>
                <option value="">Select</option>
                {totalCategory?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == catagory_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>


            <div class="form-group">
              <label for="exampleInputEmail1">
                Place Type
                <span style={{ color: "red" }}>
                 (For total area placetype should be blank) 
                </span>{" "}
                :
              </label>
              <select
                onChange={(e) => {
                  setPlacetypeid(e.target.value);
                   
                }}
              >
                <option value="">Select</option>
                {allplacetype?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == placetypeid ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.type}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
               Meterial Type<span style={{ color: "red" }}></span> :
              </label>
              <select onChange={(e) => setmeterial_id(e.target.value)}>
                <option value="">Select</option>
                
                {allmeteritype?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == meterial_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.type}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Painting Quality <span style={{ color: "red" }}></span> :
              </label>
              <select onChange={(e) => setpaintingquality_id(e.target.value)}>
                <option value="">Select</option>
                {allpaintingquality?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == paintingquality_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>
            
            <div class="form-group">
              <label for="exampleInputEmail1">
                Painting Version <span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => setpaintVer_id(e.target.value)}>
                <option value="">Select</option>
                {totalPaintVersion?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == paintVer_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

                      

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Cost<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="number"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={price}
                                onChange={(e) => setprice(e.target.value)}
                            />
                        </div>


                        
                     

                        {hide ? (
                            <button class="btn btn-primary" onClick={addLanguage}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={UpdateLanguageCategory}>
                                Update
                            </button>
                        )}



                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Manage Painting Cost
                        </div>
                        <DataTable columns={columns} data={allLanguage} pagination />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddPaintingCost;
