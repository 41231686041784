import React, { useContext } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import { LOADINGDATA } from '../App'
export default function Main() {
const {opensidebar,setopenSidebar}=useContext(LOADINGDATA)
console.log("opensidebar",opensidebar)
    const drawyerMob = () => {

        // if(opensidebar){
            setopenSidebar(false)
        $(".app-sidebar").toggleClass("sidebarmob");
        $(".sidebar-mobile-overlay").toggleClass("sidebar-mobile-open");
        $(".BurgerBox").toggleClass("BurgerActive");
    //   };
    }

    return (
        <>
            <div className='app-container app-theme-white fixed-header fixed-sidebar fixed-footer'>
                <Header opensidebar={opensidebar} setopenSidebar={setopenSidebar}/>
                <div className='app-main d-flex'>
                    {/* <Sidebar /> */}
                    <div  className='app-main__outer'>
                        <div onClick={(e)=>{e.stopPropagation();
                        console.log("main:"+opensidebar)
                            if(opensidebar)drawyerMob()}} className='app-main__inner'>
                            <Outlet />
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>

        </>
    )
}
