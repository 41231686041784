import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import "./service.css";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";

const Service = () => {
  const [name, setName] = useState("");
  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");
  const [duration, setduration] = useState("");
  const [actualPrice, setactualPrice] = useState("");
  const [discountPrice, setdiscountPrice] = useState("");
  const [summary, setsummary] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [subcategory_id, setsubcategory_id] = useState("");
  const [allState, setAllState] = useState([]);

  const [service_type_id, setservice_type_id] = useState("");

  const [totalService_type, settotalService_type] = useState([]);
  const [totalCategory, settotalCategory] = useState([]);
  const [totalSubcategory, settotalSubcategory] = useState([]);

  const[singledata,setsingleData]=useState({})

  console.log("2data", subcategory_id);
  const [fquestions, setfquestions] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  // const [image, setimage] = useState("")

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    // getAllCategory();
    // getAllSubCategory()
    fetchAllService();
    getAllService_type();
    
    // if (category_id) {
    //     getAllSubCategory(category_id)
    // }
  }, []);

  // useEffect(()=>{  
  //    service_type_id && getAllCategoryinService_type(service_type_id)
  //   },[service_type_id,])

  const resetState = () => {
    setName("");
    setactualPrice("");
    setdiscountPrice("");
    setsummary("");
    setcategory_id("");
    setsubcategory_id("");
    setAllState("");
    // setquestion("");
    setduration("");
  };

  const onEdit = (item) => {
    // getAllService_type();
    // getAllCategoryinService_type(item?.serviceTypeId)
    window.scroll(0,0)
    console.log("itemee", item);

    getAllCategoryinService_type(item?.serviceTypeId);
    getAllSubCategory(item.categoryID),
    setsingleData(item)
    setservice_type_id(item?.serviceTypeId);
  

    
    setName(item?.title);
    setactualPrice(item?.actualPrice);
    setdiscountPrice(item?.discountPrice);
    setsummary(item?.summary);

    setId(item?._id);
    setcategory_id(item.categoryID);
   
    setsubcategory_id(item.subCategoryID);
    setimage(IMAMGE_URL + item.serviceImage[0]);
    setimgdata(item.serviceImage[0]);
    setduration(item?.duration);

    // setcategory_id(item.countryId._id);
    setfquestions(item?.frequentlyQuestions);
    setHide(false);
  };

  const onDelete = (id) => {
  
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.Delete_Service(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllService();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // const organiseData = () => {
  //     let temarr = [...questio_ans]
  //     const tempobj = {
  //         question: question,
  //         answer: ans
  //     }
  //     temarr.push(tempobj);
  //     setquestio_ans(temarr)
  //     setquestion('')
  //     setans('')
  // }

  const addQuestion = () => {
    setfquestions([...fquestions, { question: "", answer: "" }]);
  };

  const updateQuestion = (index, field, value) => {
    const updatedQuestions = [...fquestions];
    updatedQuestions[index][field] = value;
    setfquestions(updatedQuestions);
  };
  const deleteQuestion = (index) => {
    const updatedQuestions = fquestions.filter((_, i) => i !== index);
    setfquestions(updatedQuestions);
  };

  const getAllService_type = async () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalService_type(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCategoryinService_type = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  // const getAllCategory = async () => {
  //   HomeService.ManageCategory()
  //     .then((res) => {
  //       console.log("ResAllBlog", res.data);

  //       if (res && res?.status) {
  //         // setLoader(false)
  //         console.log(res?.data);
  //         settotalCategory(res?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getAllSubCategory = async (id) => {
    HomeService.SubCategoriesviacategory(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log("sub_cat", res?.data);
          settotalSubcategory(res?.data);
        } else {
          settotalSubcategory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllService = () => {
    HomeService.Manage_Service()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)

          let arr = res?.data?.map((item, index) => {
            return {
              sl: index + 1,
              Name: item?.title,
              actualPrice: item?.actualPrice,
              discountPrice: item?.discountPrice,
              duration: item?.duration,
              sId:item?.serviceTypeId,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setAllState(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      console.log(IMAMGE_URL + res?.data);
      setimage(IMAMGE_URL + res?.data);
      setimgdata(res?.data);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const AddService = () => {
    const temparr = [];
    temparr.push(imgdata);
    let data = {
      serviceTypeId: service_type_id,
      categoryID: category_id,
      subCategoryID: subcategory_id,
      title: name,
      actualPrice: actualPrice,
      discountPrice: discountPrice,
      summary: summary,

      serviceProcess: [
        {
          title: "",
          description: "",
          image: "",
        },
      ],
      frequentlyQuestions: fquestions.slice(0, fquestions.length - 1),
      serviceImage: temparr,
      duration: duration,
    };

    if (
      name &&
      category_id &&
      subcategory_id &&
      actualPrice &&
      discountPrice &&
      summary &&
      fquestions&&
      imgdata && 
      service_type_id

    ) {
      // alert(name + country_id)
      HomeService.add_Service(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllService();
            resetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!imgdata) {
        toast.error("Service image Field Is Required");
      }
      if (!service_type_id) {
        toast.error("Service Type Name Field Is Required");
      }
      if (!name) {
        toast.error("Service Name Field Is Required");
      }
      if (!category_id) {
        toast.error("Category Name Field Is Required");
      }
      if (!actualPrice) {
        toast.error("ActualPrice Name Field Is Required");
      }
      if (!subcategory_id) {
        toast.error("SubCategory Name Field Is Required");
      }
      if (!discountPrice) {
        toast.error("DiscountPrice  Field Is Required");
      }
      if (!summary) {
        toast.error("Summary  Field Is Required");
      }
      if (!fquestions) {
        toast.error("FrequentlyQuestions  Field Is Required");
      }
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Name
        </div>
      ),
      selector: (row) => row.Name,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Duration
        </div>
      ),
      selector: (row) => row.duration,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          ActualPrice
        </div>
      ),
      selector: (row) => row.actualPrice,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          DiscountPrice
        </div>
      ),
      selector: (row) => row.discountPrice,
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const UpdateStateService = () => {
    const temparr = [];
    temparr.push(imgdata);

    console.log("ID", id);
    let data = {
      categoryID: category_id,
      subCategoryID: subcategory_id,
      title: name,
      actualPrice: actualPrice,
      discountPrice: discountPrice,
      summary: summary,

      serviceProcess: [
        {
          title: "",
          description: "",
          image: "",
        },
      ],
      frequentlyQuestions: fquestions,
      serviceImage: temparr,
    };

    HomeService.Update_Service(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          fetchAllService();
          resetState();
        } else {
          toast.error("Blog Category Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Service
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Service
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service-Type Name<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setservice_type_id(e.target.value);
                  getAllCategoryinService_type(e.target.value);
                  
                }}
                // value={service_type_id}
              >
                <option value="" >
                  Select{" "}
                </option>
                {totalService_type?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == service_type_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                category Name<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setcategory_id(e.target.value);

                  getAllSubCategory(e.target.value);
                }}
              >
                <option value="" >
                  Select{" "}
                </option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Subcategory Name<span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => setsubcategory_id(e.target.value)}>
                <option value="" >
                  Select{" "}
                </option>
                {totalSubcategory?.map((ele, id) => {
                  console.log("eedata", ele);

                  return (
                    <option
                      selected={ele._id == subcategory_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Name<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Duration<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={duration}
                onChange={(e) => setduration(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="formFile" class="form-label">
              Service image<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />

              {image && (
                <>
                  <img
                    style={{
                      height: "10%",
                      width: "10%",
                      marginTop: "12px",
                      borderRadius: "9px",
                    }}
                    src={image}
                  />
                </>
              )}
            </div>

            {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                Title<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={title}
                                onChange={(e) => settitle(e.target.value)}
                               
                            />
                        </div> */}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Actual Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={actualPrice}
                onChange={(e) => setactualPrice(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Discount Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={discountPrice}
                onChange={(e) => setdiscountPrice(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Summary<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={summary}
                onChange={(e) => setsummary(e.target.value)}
              />
            </div>

            {/* < div class="form-group">
                            < div class="serviceProcess">

                                <h5 style={{ textAlign: "center" }}>serviceProcess</h5>

                                < div class="form-group">
                                    <label for="exampleInputEmail1">
                                        Title<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={summary}
                                        onChange={(e) => setsummary(e.target.value)}
                                       
                                    />
                                </div>

                                < div class="form-group">
                                    <label for="exampleInputEmail1">
                                        Description<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={summary}
                                        onChange={(e) => setsummary(e.target.value)}
                                       
                                    />
                                </div>

                                < div class="form-group">
                                    <label for="formFile" class="form-label">
                                        Upload Image<span style={{ color: 'red' }}>*</span> :
                                    </label>
                                    <input
                                        id="images"
                                        onChange={imageHandler}
                                        class="form-control"
                                        type="file"
                                        accept="image/*"
                                    />
                                  
                                    {image &&
                                        <><img style={{ height: "10%", width: "10%", marginTop: '12px', borderRadius: '9px' }} src={image} />
                                            <button onClick={() => HandleCrossClick()} style={{ color: 'red' }} type="button" class="btn-close" aria-label="Close"></button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div> */}

            {fquestions.map((q, index) => {
              return (
                <div class="form-group">
                  <div class="serviceProcess">
                    <h5 style={{ textAlign: "center" }}>frequentlyQuestions</h5>
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        Question<span style={{ color: "red" }}>*</span> :
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={q.question}
                        onChange={(e) =>
                          updateQuestion(index, "question", e.target.value)
                        }
                      />
                    </div>

                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        Answar<span style={{ color: "red" }}>*</span> :
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={q.answer}
                        onChange={(e) =>
                          updateQuestion(index, "answer", e.target.value)
                        }
                      />
                    </div>
                    <div style={{ display: "flex", float: "right" }}>
                      <div
                        style={{
                          float: "right",
                          display:
                            index == fquestions.length - 1 ? "block" : "none",
                        }}
                      >
                        <button style={{ color: "blue" }} onClick={addQuestion}>
                          Add More{" "}
                        </button>
                      </div>

                      <div
                        onClick={() => {
                          deleteQuestion(index);
                        }}
                        style={{
                          float: "right",
                          display: index == 0 ? "none" : "block",
                        }}
                      >
                        <button style={{ color: "blue" }}>Delete </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* 
                        < div class="form-group">
                            < div class="serviceProcess">


                                <h5 style={{ textAlign: "center" }}>frequentlyQuestions</h5>
                                < div class="form-group">
                                    <label for="exampleInputEmail1">
                                        Question<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={question}
                                        onChange={(e) => setquestion(e.target.value)}
                                       
                                    />
                                </div>

                                < div class="form-group">
                                    <label for="exampleInputEmail1">
                                        Answar<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={ans}
                                        onChange={(e) => setans(e.target.value)}
                                       
                                    />
                                </div>
                                <div style={{ float: "right" }}><button style={{ color: "blue" }} onClick={organiseData}>Add More </button></div>

                            </div>

                        </div> */}

            {hide ? (
              <button class="btn btn-primary" onClick={AddService}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateStateService}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Service
            </div>
            <DataTable columns={columns} data={allState} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
