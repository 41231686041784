import React, { useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { TbCategory } from "react-icons/tb";
// import { VscDashboard  } from "react-icons/vsc";

export default function Sidebar({toglebtn}) {
  const active = () => {
    $(".metismenu-link").toggleClass("active");
  };

  return (
    <>
      <div className="sidebar-mobile-overlay" style={{display:toglebtn?"block":"none"}}></div>
      <div
        className="app-sidebar bg-royal sidebar-text-light sidebar-shadow appear-done enter-done fade-in slide-right"
        id="sidebar"
      >
        <div className="app-sidebar__inner">
          <div className="metismenu vertical-nav-menu">
            <ul className="metismenu-container">
              <li className="metismenu-item">
                <Link
                  className="metismenu-link"
                  to="/"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Dashboard
                </Link>
              </li>
            </ul>
          </div>

          <div
            className="panel-group"
            id="accordionMenu"
            role="tablist"
            aria-multiselectable="true"
          >
            {/* Other panels */}
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingcountry"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapsecountry"
                  aria-expanded="false"
                  aria-controls="collapsecountry"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Country
                </a>
              </div>
            </div>
            <div
              id="collapsecountry"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingcountry"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-country" className="metismenu-link">
                      Add Country
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingstate"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapsestate"
                  aria-expanded="false"
                  aria-controls="collapsestate"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  State
                </a>
              </div>
            </div>
            <div
              id="collapsestate"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingstate"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-state" className="metismenu-link">
                      Add State
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingcity"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapsecity"
                  aria-expanded="false"
                  aria-controls="collapsecity"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  City
                </a>
              </div>
            </div>
            <div
              id="collapsecity"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingcity"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-city" className="metismenu-link">
                      Add City
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingarea"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapsearea"
                  aria-expanded="false"
                  aria-controls="collapsearea"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Area
                </a>
              </div>
            </div>
            <div
              id="collapsearea"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingarea"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-area" className="metismenu-link">
                      Add Area
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headinglanguage"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapselanguage"
                  aria-expanded="false"
                  aria-controls="collapselanguage"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Language
                </a>
              </div>
            </div>
            <div
              id="collapselanguage"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headinglanguage"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-language" className="metismenu-link">
                      Add Language
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingservice"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseservice"
                  aria-expanded="false"
                  aria-controls="collapseservice"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Service-Type
                </a>
              </div>
            </div>
            <div
              id="collapseservice"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingservice"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-service" className="metismenu-link">
                      Add service Type
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingcategory"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapsecategory"
                  aria-expanded="false"
                  aria-controls="collapsecategory"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Category
                </a>
              </div>
            </div>
            <div
              id="collapsecategory"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingcategory"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-category" className="metismenu-link">
                      Add Category
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingsubcategory"
            >
              {" "}
              {/* Change id to headingsubcategory */}
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapsesubcategory"
                  aria-expanded="false"
                  aria-controls="collapsesubcategory"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  SubCategory {/* Change text to SubCategory */}
                </a>
              </div>
            </div>
            <div
              id="collapsesubcategory"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingsubcategory"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-subcategory" className="metismenu-link">
                      {" "}
                      {/* Change link to "/add-subcategory" */}
                      Add SubCategory {/* Change text to Add SubCategory */}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingexperence"
            >
              {" "}
              {/* Change id to headingsubcategory */}
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseexperence"
                  aria-expanded="false"
                  aria-controls="collapseexperence"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Service {/* Change text to SubCategory */}
                </a>
              </div>
            </div>
            <div
              id="collapseexperence"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingexperence"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/service" className="metismenu-link">
                      {" "}
                      {/* Change link to "/add-subcategory" */}
                      Add Service {/* Change text to Add SubCategory */}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingservicedate"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseservicedate"
                  aria-expanded="false"
                  aria-controls="collapseservicedate"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Service Date
                </a>
              </div>
            </div>
            <div
              id="collapseservicedate"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingservicedate"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/service-date" className="metismenu-link">
                      Add Service Date
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingservice"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseservice"
                  aria-expanded="false"
                  aria-controls="collapseservice"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  ServiceTime
                </a>
              </div>
            </div>
            <div
              id="collapseservice"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingservice"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/service-time" className="metismenu-link">
                      Add ServiceTime
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="heading-coupon"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-coupon"
                  aria-expanded="false"
                  aria-controls="collapse-coupon"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Coupon
                </a>
              </div>
            </div>
            <div
              id="collapse-coupon"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="heading-coupon"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/Add-coupon" className="metismenu-link">
                      Add Coupon
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-booking`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-booking`}
                  aria-expanded="false"
                  aria-controls={`collapse-booking`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Booking
                </a>
              </div>
            </div>
            <div
              id={`collapse-booking`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-booking`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/Add-booking" className="metismenu-link">
                      Add Booking
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${1}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${1}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${1}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  InterviewDate
                </a>
              </div>
            </div>
            <div
              id={`collapse-${1}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${1}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/Add-interviewdate" className="metismenu-link">
                      Add InterviewDate
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${2}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${2}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${2}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  InterviewTime
                </a>
              </div>
            </div>
            <div
              id={`collapse-${2}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${2}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddInterviewTime" className="metismenu-link">
                      Add InterviewTime
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${3}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${3}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${3}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  InterviewType
                </a>
              </div>
            </div>
            <div
              id={`collapse-${3}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${3}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddInterviewType" className="metismenu-link">
                      Add InterviewType
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${4}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${4}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${4}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Experience
                </a>
              </div>
            </div>
            <div
              id={`collapse-${4}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${4}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddExperience" className="metismenu-link">
                      Add Experience
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${5}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${5}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${5}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Working Time
                </a>
              </div>
            </div>
            <div
              id={`collapse-${5}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${5}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/WorkingTime" className="metismenu-link">
                      Add Working Time
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${6}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${6}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${6}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Registration fee
                </a>
              </div>
            </div>
            <div
              id={`collapse-${6}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${6}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/RegistrationFee" className="metismenu-link">
                      Add Registration fee
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${10}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${10}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${10}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  SpareParts
                </a>
              </div>
            </div>
            <div
              id={`collapse-${10}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${10}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/SpareParts" className="metismenu-link">
                      Add SpareParts
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${7}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${7}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${7}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Category Banner
                </a>
              </div>
            </div>
            <div
              id={`collapse-${7}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${7}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/CategoryBanner" className="metismenu-link">
                      Add Category Banner
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${8}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${8}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${8}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Home Banner
                </a>
              </div>
            </div>
            <div
              id={`collapse-${8}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${8}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/HomeBanner" className="metismenu-link">
                      Add Home Banner
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${13}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${13}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${13}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  SubCategory Banner
                </a>
              </div>
            </div>
            <div
              id={`collapse-${13}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${13}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/SubCategory_Banner" className="metismenu-link">
                      Add SubCategory Banner
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${14}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${14}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${14}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Vendar
                </a>
              </div>
            </div>
            <div
              id={`collapse-${14}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${14}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/VendarDetails" className="metismenu-link">
                      All Vendar
                    </Link>

                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddVendorbanner" className="metismenu-link">
                      Add Vendar Bannar
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link style={{objectFit:"contain",overflow:"hidden"}} to="/AddVendarInterviewQuestion" className="metismenu-link">
                       Vendar
                      InterviewQuestion
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddVendarQuestionAnswar" className="metismenu-link">
                    Add Vendar Query. 

                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddSubscription" className="metismenu-link">
                    Add Subscription 

                    </Link>
                  </li>
                  
                  
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${15}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${15}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${15}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                 Job From Input
                </a>
              </div>
            </div>
            <div
              id={`collapse-${15}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${15}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddHouseType" className="metismenu-link">
                      Add HouseType
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddBuildingType" className="metismenu-link">
                      Add BuildingType
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddNoofBuilding" className="metismenu-link">
                    Add No of Flat
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddPlaceType" className="metismenu-link">
                      Add PlaceType
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddModelType" className="metismenu-link">
                      Add ModelType
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddMeterialType" className="metismenu-link">
                      Add MeterialType
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AreaIndivisual" className="metismenu-link">
                      Add Indivisual Area
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddPaintingBrands" className="metismenu-link">
                      Add Painting Brands
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddPaintingQuality" className="metismenu-link">
                      Add Painting Quality
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddPaintingVersion" className="metismenu-link">
                      Add Painting Version
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddServiceDays" className="metismenu-link">
                    Add Service Days
                    </Link>
                  </li>

                  <li className="metismenu-item">
                    <Link to="/ServicePakages" className="metismenu-link">
                    Service Pakages
                    </Link>
                  </li>

                  <li className="metismenu-item">
                    <Link to="/AddDiscountPakeges" className="metismenu-link">
                    discount Pakages
                    </Link>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${16}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${16}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${16}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                 Contuct us
                </a>
              </div>
            </div>
            <div
              id={`collapse-${16}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${16}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/ViewContuctUs" className="metismenu-link">
                      View Contuct Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${16}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${16}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${16}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  BuildingType
                </a>
              </div>
            </div>
            <div
              id={`collapse-${16}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${16}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddBuildingType" className="metismenu-link">
                      Add BuildingType
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${17}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${17}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${17}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  PlaceType
                </a>
              </div>
            </div>
            <div
              id={`collapse-${17}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${17}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddPlaceType" className="metismenu-link">
                      Add PlaceType
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${18}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${18}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${18}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  ModelType
                </a>
              </div>
            </div>
            <div
              id={`collapse-${18}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${18}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddModelType" className="metismenu-link">
                      Add ModelType
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${19}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${19}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${19}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  MeterialType
                </a>
              </div>
            </div>
            <div
              id={`collapse-${19}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${19}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddMeterialType" className="metismenu-link">
                      Add MeterialType
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${20}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${20}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${20}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  AreaIndivisual
                </a>
              </div>
            </div>
            <div
              id={`collapse-${20}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${20}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AreaIndivisual" className="metismenu-link">
                      Add Indivisual Area
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${21}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${21}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${21}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Painting
                </a>
              </div>
            </div>
            <div
              id={`collapse-${21}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${21}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddPaintingBrands" className="metismenu-link">
                      Add Painting Brands
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddPaintingQuality" className="metismenu-link">
                      Add Painting Quality
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddPaintingVersion" className="metismenu-link">
                      Add Painting Version
                    </Link>
                  </li>
                  <li className="metismenu-item">
                    <Link to="/AddServiceDays" className="metismenu-link">
                    Add Service Days
                    </Link>
                  </li>
                  

                </ul>
              </div>
            </div>
          </div> */}
 <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${17}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${17}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${17}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Order Offer Image
                </a>
              </div>
            </div>
            <div
              id={`collapse-${17}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${17}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddOrderService" className="metismenu-link">
                    Add Order Offer Image
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${18}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${18}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${18}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
             About Service
                </a>
              </div>
            </div>
            <div
              id={`collapse-${18}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${18}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddAboutService" className="metismenu-link">
                    Add   About Service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${19}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${19}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${19}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
            PaintingCost
                </a>
              </div>
            </div>
            <div
              id={`collapse-${19}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${19}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddPaintingCost" className="metismenu-link">
                    Add Painting Cost
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${20}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${20}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${20}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
          No of Building
                </a>
              </div>
            </div>
            <div
              id={`collapse-${20}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${20}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddNoofBuilding" className="metismenu-link">
                    AddNoofBuilding
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id={`heading-${21}`}
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${21}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${21}`}
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
      AMCprice
                </a>
              </div>
            </div>
            <div
              id={`collapse-${21}`}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby={`heading-${21}`}
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/AddAMCprice" className="metismenu-link">
                    AddAMCprice
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </>
  );
}
