import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import { useParams,Link } from "react-router-dom";

const AddHouseTypeDetails = () => {
  const [name, setName] = useState("");

  const [state_id, setstate_id] = useState("");
  const [image, setimage] = useState("");
  const [imgdata, setimgdata] = useState([]);
  console.log(state_id);
  const [allState, setAllState] = useState([]);
  const [allVendar, setAllVendar] = useState([]);
  const [allviewdata, setallviewdata] = useState([]);
  // const [totalCountry, settotalCountry] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  let { Sid, Cid } = useParams();
  // let { Cid } = useParams()
  console.log("gg", Cid);

  useEffect(() => {
    ViewparticularHomedetailsofCategories(Sid, Cid);
  }, []);

  // const onEdit = (item) => {
  //     window.scrollTo(0, 0);
  //     console.log("item", item);
  //     setName(filterdate(item?.servicedate));
  //     setId(item?._id);
  //     setstate_id(item.category_id);
  //     setHide(false);
  // };

  // const onDelete = (id) => {
  //     Swal.fire({
  //         title: "Are you sure?",
  //         // text: "You won't  to delete this!",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Yes, delete it!",
  //     }).then((result) => {
  //         if (result.isConfirmed) {
  //             HomeService.Delete_Servicedate(id)
  //                 .then((res) => {
  //                     console.log("DELETEBLOGCAT", res);
  //                     if (res && res.status) {
  //                         toast.success("Deleted Successfully");
  //                         console.log("RESPONSEDELETE", res);
  //                         fetchAllState();
  //                     } else {
  //                         toast.error("Failed to Delete the Item ");
  //                     }
  //                 })
  //                 .catch((err) => {
  //                     console.log(err);
  //                 });
  //         }
  //     });
  // };

  const ViewparticularHomedetailsofCategories = (sid, cid) => {
    const data = { serviceCategoryID: cid };
    HomeService.viewParticularCategoryHomeType(sid, data)
      .then((res) => {
        console.log("", res.data);
        if (res && res?.status) {
          // setLoader(false)
          setAllVendar(res?.data);

          let arr = res?.data?.map((item, index) => {
            console.log("ResAllBlogtt", item);

            return {
              sl: index + 1,
              serviceName: item?.serviceTypeData?.serviceName,
              CatgoryName: item?.categoryData?.name,
              name: item?.name,

              // action: (
              //     <div style={{ display: "flex", flexDirection: "coloum" }}>
              //         <svg
              //             onClick={() => onEdit(item)}
              //             style={{
              //                 height: "20px",
              //                 width: "20px",
              //                 cursor: "pointer",
              //                 marginRight: "20px",
              //             }}
              //             xmlns="http://www.w3.org/2000/svg"
              //             width="16"
              //             height="16"
              //             fill="currentColor"
              //             class="bi bi-pencil-square"
              //             viewBox="0 0 16 16"
              //         >
              //             <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              //             <path
              //                 fill-rule="evenodd"
              //                 d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              //             />
              //         </svg>
              //         <svg
              //             onClick={() => onDelete(item?._id)}
              //             style={{
              //                 color: "red",
              //                 height: "20px",
              //                 cursor: "pointer",
              //                 width: "20px",
              //             }}
              //             xmlns="http://www.w3.org/2000/svg"
              //             width="16"
              //             height="16"
              //             fill="currentColor"
              //             class="bi bi-trash3"
              //             viewBox="0 0 16 16"
              //         >
              //             <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
              //         </svg>
              //     </div>
              // ),
            };
          });
          setallviewdata(arr);

          console.log("RESPgONSE", arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          sl
        </div>
      ),
      selector: (row) => row.sl,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type Name
        </div>
      ),
      selector: (row) => row.serviceName,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category Name
        </div>
      ),
      selector: (row) => row.CatgoryName,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          HouseType Name
        </div>
      ),
      selector: (row) => row.name,
    },
  ];

  // const UpdateStateCategory = () => {
  //     console.log("ID", id);
  //     let data = {
  //         category_id: state_id,
  //         servicedate: name,
  //     };
  //     HomeService.Update_ServiceDate(id, data)
  //         .then((res) => {
  //             console.log("updated res", res);
  //             if (res && res.status) {
  //                 toast.success("Updated Successfully");
  //                 setHide(true);
  //                 console.log("RESPONSEDELETE", res);
  //                 setName("");
  //                 setstate_id("");
  //                 ViewparticularVendar();
  //             } else {
  //                 toast.error("Blog Category Name Already Exist");
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });
  // };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            View all Category HouseType Data
            <DataTable columns={columns} data={allviewdata} pagination />
          </div>
          <div className="card-body"><Link to="/AddHouseType"><button className="button">Back</button></Link></div>
        </div>
      </div>
    </>
  );
};

export default AddHouseTypeDetails;
