import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css"
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import { columns } from "../../Constant";

const AddBooking = () => {
  const [name, setName] = useState("");
  // const [title, settitle] = useState('')
  const [exp_date, setexp_date] = useState("");
  const [couponPercent, setcouponPercent] = useState("");
  const [allState, setAllState] = useState([]);
  const [description, setdescription] = useState("");

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    fetchAllBooking();
  }, []);

  const resetState = () => {
    setName("");
    setexp_date("");
    setcouponPercent("");
    setdescription("");

    // setAllState('')
    // setquestion("")
  };
  const onEdit = (item) => {
    console.log("item", item);
    setName(item?.name);
    setId(item?._id);
    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteState(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              // fetchAllCategory();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const fetchAllBooking = () => {
    HomeService.ManageBookingCheckout()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)

          let arr = res?.data?.map((item, index) => {
            return {
              sl: index + 1,

              quantity: item?.quantity,
              subtotal: item?.subtotal,
              name: item?.name,
               image: IMAMGE_URL+item?.image,
              price: item?.price,
              total: item?.total,
              itemDiscount: item?.itemDiscount,

              couponCode: item?.couponCode,
              couponPrice: item?.couponPrice,
              membershipDiscount: item?.membershipDiscount,
              totalAmount: item?.totalAmount,
              address: item?.address,
              zipcode: item?.zipcode,
              landmark: item?.landmark,
              city: item?.city,

              mobileNumber: item?.mobileNumber,
              addressType: item?.addressType,
              paymentMethod: item?.paymentMethod,
              paymenttype: item?.paymenttype,
              bookingStatus: item?.bookingStatus,
              booking_date: item?.booking_date,
              discount_percent: item?.discount_percent,
              updatedOn: item?.updatedOn,
              coupon:item?.Coupon[0]?.couponCode,

              
           
              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setAllState(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const AddService = () => {
  //   let data = {
  //     couponCode: name,
  //     quantity: quantity,
  //     subtotal: subtotal,
  //     firstname: firstname,
  //     lastname: lastname,
  //     price: price,
  //     total: total,
  //     itemDiscount: itemDiscount,

  //     couponCode: couponCode,
  //     couponPrice: couponPrice,
  //     membershipDiscount: membershipDiscount,
  //     totalAmount: totalAmount,
  //     address: address,
  //     zipcode: zipcode,
  //     landmark: landmark,
  //     city: city,

  //     mobileNumber: mobileNumber,
  //     addressType: addressType,
  //     paymentMethod: paymentMethod,
  //     paymenttype: paymenttype,
  //     bookingStatus: bookingStatus,
  //     booking_date: booking_date,
  //     discount_percent: discount_percent,
  //     updatedOn: updatedOn,
  //   };

  //   if (name && couponPercent && exp_date && description) {
  //     // alert(name + country_id)
  //     HomeService.AddCupon(data)
  //       .then((res) => {
  //         console.log("Response Add State", res);
  //         if (res && res.status) {
  //           toast.success(res.message);
  //           fetchAllBooking();
  //           resetState();
  //         } else {
  //           toast.error(res.message);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     if (!name) {
  //       toast.error("CouponCode Field Is Required");
  //     }

  //     if (!couponPercent) {
  //       toast.error("CouponPercent Field Is Required");
  //     }
  //     if (!description) {
  //       toast.error("Description Field Is Required");
  //     }
  //   }
  // };

  //     {
  //         name: (
  //             <div
  //                 style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //             >
  //                 SL
  //             </div>
  //         ),
  //         selector: (row) => row.sl,
  //     },
  //     {
  //         name: (
  //             <div
  //                 style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //             >
  //                 Coupon Code
  //             </div>
  //         ),
  //         selector: (row) => row.Name,
  //     },

  //     {
  //         name: (
  //             <div
  //                 style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //             >
  //                 Expirydate
  //             </div>
  //         ),
  //         selector: (row) => row.expirydate,
  //     },
  //     {
  //         name: (
  //             <div
  //                 style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //             >
  //                 Details
  //             </div>
  //         ),
  //         selector: (row) => row.description,
  //     },

  //     {
  //         name: (
  //             <div
  //                 style={{
  //                     fontSize: "14px",
  //                     color: "#495057",
  //                     marginLeft: "15px",
  //                     fontWeight: "bolder",
  //                 }}
  //             >
  //                 Action
  //             </div>
  //         ),
  //         selector: (row) => row.action,
  //     },
  // ];

  const UpdateStateService = () => {
    console.log("ID", id);
    let data = {
      name: name,
    };

    HomeService.Update_Service(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          fetchAllBooking();
          resetState();
        } else {
          toast.error("Blog Category Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {/* {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add Coupon
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Edit Coupon
                            </div>
                        )}



                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Coupon Code<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                               
                            />
                        </div>


                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Expiry Date<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="date"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={exp_date}
                                onChange={(e) => setexp_date(e.target.value)}
                               
                            />
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Description<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                               
                            />
                        </div>


                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                CouponPercent<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="number"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={couponPercent}
                                onChange={(e) => setcouponPercent(e.target.value)}
                               
                            />
                        </div>







                        {hide ? (
                            <button class="btn btn-primary" onClick={AddService}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={UpdateStateService}>
                                Update
                            </button>
                        )} */}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Booking
            </div>
            <DataTable columns={columns} data={allState} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBooking;
