import React from 'react'

function Nopage() {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "80vh", alignItems: "center", justifyContent: "center" }}>     <h1>404 Not Found</h1>
            <p>Oops! The page you are looking for does not exist.</p>
            <p>Please check the URL or return to the homepage.</p>
            <a href="/">Go to Homepage</a>
        </div>
    )
}

export default Nopage